import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import icon_1 from '../../../../assets/S4G/s4g_icon_1.png';
import icon_2 from '../../../../assets/S4G/s4g_icon_2.png';
import icon_3 from '../../../../assets/S4G/s4g_icon_3.png';
const messages = defineMessages({
    iconTitle_1: 'Humans',
    iconTitle_2: 'Animals',
    iconTitle_3: 'The Planet',
});
const icons = [icon_1, icon_2, icon_3];
const Icons = () => {
    return (React.createElement(Container, null, icons.map((icon, index) => {
        return (React.createElement(Icon, { key: index },
            React.createElement(Wrapper, null,
                React.createElement("img", { src: icon, alt: "Icon" })),
            React.createElement(FormattedMessage, { ...messages[`iconTitle_${index + 1}`] })));
    })));
};
const Container = styled.div `
  display: flex;
  justify-content: center;
  margin-top: 50px;

  ${media.desktop `
    margin-top: 70px;
  `}
`;
const Icon = styled.div `
  width: 80px;
  margin: 0 20px;

  ${media.desktop `
    width: 120px;
    margin: 0 32px;
  `}

  img {
    width: 64px;
  }

  span {
    display: block;
    text-align: center;
    font-size: 16px;
    color: #031753;
    line-height: 1.4;
    padding-top: 17px;

    ${media.desktop `
      font-size: 18px;
    `}
  }
`;
const Wrapper = styled.div `
  width: 80px;
  height: 80px;
  box-shadow: 0 20px 40px 0 rgba(30, 68, 108, 0.1);
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.desktop `
    width: 120px;
  height: 120px;
  `}
`;
export default Icons;
