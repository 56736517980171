import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import icon_1 from '../../../../assets/S4G/s4g_icon_4.png';
import icon_2 from '../../../../assets/S4G/s4g_icon_5.png';
import icon_3 from '../../../../assets/S4G/s4g_icon_6.png';
import icon_4 from '../../../../assets/S4G/s4g_icon_7.png';
const messages = defineMessages({
    title_1: 'Connect',
    caption_1: 'We find a corporate donor to sponsor your campaign.',
    title_2: 'Go live',
    caption_2: 'Your campaign goes live in the Sweatcoin app for a week.',
    title_3: 'Donate',
    caption_3: 'Users donate their sweatcoins to hit your fundraising goal.',
    title_4: 'Release',
    caption_4: 'When the sweatcoin target is hit, the donor releases the funds.',
});
const icons = [icon_1, icon_2, icon_3, icon_4];
const FundraisingCards = () => {
    return (React.createElement(Container, null, icons.map((icon, index) => {
        return (React.createElement(Item, { key: index },
            React.createElement(Icon, { src: icon }),
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages[`title_${index + 1}`] })),
            React.createElement(Caption, null,
                React.createElement(FormattedMessage, { ...messages[`caption_${index + 1}`] }))));
    })));
};
const Container = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.desktop `
    flex-direction: row;
  `};
`;
const Item = styled.div `
  width: 290px;
  margin: 25px auto;
  box-sizing: border-box;
  padding: 0 12px;
  text-align: center;

  ${media.desktop `
    width: 262px;
    height: 276px;
    background-color: #ffffff;
    margin: 60px 12px 120px;
    padding: 40px 30px;
    border-radius: 8px;
    box-shadow: 0 30px 60px 0 rgba(30, 68, 108, 0.08);
    text-align: left;
  `};
`;
const Icon = styled.img `
  height: 64px;
  margin: 0 0 25px;
`;
const Title = styled.h4 `
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 8px;
  font-weight: bold;
`;
const Caption = styled.p `
  font-size: 16px;
  line-height: 140%;
  color: rgba(3, 23, 83, 0.5);
`;
export default FundraisingCards;
