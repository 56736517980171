import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import buttonArrow from '../../../../assets/S4G/button-arrow.svg';
const messages = defineMessages({
    title: 'Give walking <span>more purpose</span>',
    ctaTitle: 'BECOME AN S4G PARTNER',
});
const Footer = ({ onClickHandler }) => {
    return (React.createElement(Container, null,
        React.createElement(FormattedMessage, { ...messages.title }, (title) => React.createElement(Title, null, title)),
        React.createElement(FormLink, { onClick: () => onClickHandler(true) },
            React.createElement("span", null,
                React.createElement(FormattedMessage, { ...messages.ctaTitle })),
            React.createElement("br", null),
            React.createElement("img", { src: buttonArrow, alt: "Arrow icon" }))));
};
const Container = styled.div `
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  background-color: rgba(253, 139, 123, 0.04);
  overflow: hidden;
  border-radius: 10px;
  padding: 40px 0 50px;
  position: relative;

  ${media.desktop `
    flex-direction: row;
    margin: 125px 0 120px;
    padding: 63px 70px 60px;
    justify-content: space-between;
    align-items: flex-start;
  `};

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 8px;
    background-color: #fd8b7b;
    top: 0;
    left: 0;
    right: 0;
  }
`;
const FormLink = styled.button `
  box-sizing: border-box;
  background-color: #fd8b7b;
  overflow: visible;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  margin: 25px 20px 0;
  padding: 13px 0 8px;
  display: block;
  transition: all 0.2s linear;

  ${media.desktop `
    width: 340px;
    margin-top: 9px;
  `};

  &:hover {
    background-color: #e26e5e;
  }
`;
const Title = styled.h2 `
  line-height: 100%;
  color: #031753;
  font-size: 42px;
  font-weight: 400;
  text-align: center;
  span {
    display: block;
    color: #fd8b7b;
  }

  ${media.desktop `
    text-align: left;
  `};
`;
export default Footer;
