import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'Our Vision',
    text_1: 'Sweatcoin is a free app which <strong>rewards your daily steps with a new-generation currency</strong> you can spend on cool products or donate to charity.',
    text_2: 'Why? Because when you look after your health, you benefit society. You are more productive. You help save $billions in healthcare. Your movement has value: <strong>you deserve a share in it</strong>.',
    registeredUsersAmount: '120M+',
    registeredUsersCaption: 'Registered users',
    usersToComeAmount: '1BN',
    usersToComeCaption: 'Users to come',
});
const Vision = ({}) => {
    return (React.createElement(Wrapper, { id: "vision" },
        React.createElement(Text, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement("p", null,
                React.createElement(FormattedMessage, { ...messages.text_1 })),
            React.createElement("p", null,
                React.createElement(FormattedMessage, { ...messages.text_2 }))),
        React.createElement(Numbers, null,
            React.createElement(NumbersTitle, null,
                React.createElement(FormattedMessage, { ...messages.registeredUsersAmount })),
            React.createElement(NumbersSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.registeredUsersCaption })),
            React.createElement(NumbersTitle, null,
                React.createElement(FormattedMessage, { ...messages.usersToComeAmount })),
            React.createElement(NumbersSubtitle, null,
                React.createElement(FormattedMessage, { ...messages.usersToComeCaption })))));
};
const Wrapper = styled.div `
  margin: 50px 16px 16px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${desktop()} {
    width: 980px;
    padding: 150px 0 120px;
    margin: 0 auto;
    flex-direction: row;
  }
`;
const Text = styled.div `
  @media ${desktop()} {
    width: 600px;
    flex: none;
    margin-right: 100px;
  }

  p {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 0.8);
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 18px;

    strong {
      color: #fff;
    }

    @media ${desktop()} {
      font-size: 24px;
      line-height: 1.6;
      margin-bottom: 34px;
    }
  }
`;
const Title = styled.h3 `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 18px;

  @media ${desktop()} {
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 24px;
  }
`;
const Numbers = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 25px;

  @media ${desktop()} {
    padding-top: 85px;
  }
`;
const NumbersTitle = styled.h4 `
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 72px;
  letter-spacing: -1px;
  line-height: 54px;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(151, 143, 255, 1) 100%);
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  margin-bottom: 16px;

  @media ${desktop()} {
    margin-bottom: 20px;
  }
`;
const NumbersSubtitle = styled.p `
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 34px;

  @media ${desktop()} {
    margin-bottom: 70px;
  }
`;
export default Vision;
