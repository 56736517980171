import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import buttonArrow from '../../../../assets/S4G/button-arrow.svg';
import charitySrc from '../../../../assets/S4G/charity_cover.jpg';
import partnerSrc from '../../../../assets/S4G/partner_cover.jpg';
const messages = defineMessages({
    charityTitle: 'Are you a charity?',
    charityCta: 'Get featured',
    sponsorTitle: 'Are you a sponsor?',
    sponsorCta: 'Become a sponsor',
    title: 'More ways to partner with us',
    header_1: 'Events',
    caption_1: 'We work with entertainment, educational, and active events. We can create exclusive marketplaces filled with deep discounts and event partner products, accessible only to participants of your event. We also run friendly competitions with live leaderboard step counts and set targets for the participants to complete as',
    header_2: 'Custom partnerships',
    caption_2: "We're eager to use our tech for good. We can build out customised partnerships to support humanitarian, animal welfare, and environmental issues.",
});
const cards = [
    {
        cover: charitySrc,
        title: React.createElement(FormattedMessage, { ...messages.charityTitle }),
        cta: React.createElement(FormattedMessage, { ...messages.charityCta }),
    },
    {
        cover: partnerSrc,
        title: React.createElement(FormattedMessage, { ...messages.sponsorTitle }),
        cta: React.createElement(FormattedMessage, { ...messages.sponsorCta }),
    },
];
const PartnersCards = ({ onClickHandler }) => {
    return (React.createElement(Container, null,
        React.createElement(Cards, null, cards.map((item, index) => {
            const { cover, title, cta } = item;
            return (React.createElement(Card, { key: index },
                React.createElement(Cover, { src: cover, alt: "Sweatcoin charity" }),
                React.createElement(Title, null, title),
                React.createElement(FormLink, { onClick: () => onClickHandler(true) },
                    React.createElement("span", null, cta),
                    React.createElement("br", null),
                    React.createElement("img", { src: buttonArrow, alt: "Arrow icon" }))));
        })),
        React.createElement(WaysBlock, null,
            React.createElement(WaysTitle, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(WaysColumn, null,
                React.createElement(SmallTitle, null,
                    React.createElement(FormattedMessage, { ...messages.header_1 })),
                React.createElement(Caption, null,
                    React.createElement(FormattedMessage, { ...messages.caption_1 }))),
            React.createElement(WaysColumn, null,
                React.createElement(SmallTitle, null,
                    React.createElement(FormattedMessage, { ...messages.header_2 })),
                React.createElement(Caption, null,
                    React.createElement(FormattedMessage, { ...messages.caption_2 }))))));
};
const Container = styled.div `
  ${media.desktop `
    background-color: rgba(236, 238, 243, 0.4);
    border-radius: 10px;
    margin-top: 347px;
    padding-bottom: 75px;
  `};
`;
const Cards = styled.div `
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${media.desktop `
    flex-direction: row;
  `}
`;
const Card = styled.div `
  padding: 0 12px 35px;

  ${media.desktop `
    box-shadow: 0 40px 80px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    border-radius: 10px;
    width: 448px;
    padding: 10px 10px 50px;
    margin: -227px 12px 80px;
  `}
`;
const Cover = styled.img `
  width: 100%;
  height: auto;
  border-radius: 5px;
`;
const Title = styled.h4 `
  text-align: center;
  line-height: 140%;
  color: #031753;
  font-size: 32px;
  margin-top: 20px;

  ${media.desktop `
    font-size: 42px;
    margin-top: 50px;
  `}
`;
const FormLink = styled.button `
  box-sizing: border-box;
  background-color: #fd8b7b;
  overflow: visible;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  padding: 13px 0 8px;
  width: 288px;
  display: block;
  margin: 15px auto 0;
  transition: all 0.2s linear;

  ${media.desktop `
    margin: 40px auto 0;
  `}

  &:hover {
    background-color: #e26e5e;
  }
`;
const WaysBlock = styled.div `
  margin-top: 15px;
  padding: 38px 25px 10px;
  background-color: #f7f8fa;

  ${media.desktop `
    width: 920px;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
    background-color: transparent;
  `}
`;
const WaysTitle = styled(Title) `
  margin: 0 0 20px;
  text-align: left;
  font-size: 24px;

  ${media.desktop `
    width: 280px;
    margin: -15px 55px 0 0;
    font-size: 32px;
  `}
`;
const WaysColumn = styled.div `
  margin-bottom: 25px;

  ${media.desktop `
    width: 230px;
  `}
`;
const SmallTitle = styled.h4 `
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 10px;
  font-weight: bold;

  ${media.desktop `
    margin-bottom: 8px;
  `}
`;
const Caption = styled.p `
  font-size: 16px;
  line-height: 140%;
  color: rgba(3, 23, 83, 0.5);
`;
export default PartnersCards;
