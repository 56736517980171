import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import audible from 'assets/Home/audible.svg';
import awf from 'assets/Home/awf_logo.png';
import cruk from 'assets/Home/cruk_logo.png';
import headspace from 'assets/Home/headspace.svg';
import bag from 'assets/Home/home_bag.png';
import heart from 'assets/Home/home_heart.png';
import arrow from 'assets/Home/link-arrow.png';
import stc from 'assets/Home/stc_logo.svg';
import tidal from 'assets/Home/tidal.svg';
import yoga_club from 'assets/Home/yoga_club.svg';
import { desktop } from '../utils/mediaQueries';
const messages = defineMessages({
    title: 'Walk. Earn…',
    subtitle: 'A few simple steps',
    spendHeading: 'Spend',
    spendText: 'Choose from 1000s of fabulous branded goods and services in our marketplace from high-tech shoes to iPhones, anti-gravity yoga classes, Apple Watches and more!',
    donateHeading: 'Donate',
    donateText: 'to a variety of environmental, humanitarian and animal preservation causes',
    findOutMore: 'Find out more',
    exchangeHeading: 'Exchange',
    exchangeText: 'you can now convert Sweatcoins into SWEAT. Find out more about how you can opt into, and start generating, SWEAT with your steps.',
});
const SimpleSteps = () => {
    const [toggle, setToggle] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
            setToggle((toggle) => !toggle);
        }, 3000);
        return () => clearInterval(interval);
    }, []);
    return (React.createElement(Wrapper, { id: "how-it-works" },
        React.createElement(Subtitle, null,
            React.createElement(FormattedMessage, { ...messages.subtitle })),
        React.createElement(Title, null,
            React.createElement(FormattedMessage, { ...messages.title })),
        React.createElement(Block, null,
            React.createElement(ContentLeft, null,
                React.createElement(BlockTitle, null,
                    React.createElement(FormattedMessage, { ...messages.spendHeading })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.spendText })),
                React.createElement(LogosTop, null, toggle ? (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: headspace, width: "152", height: "34", alt: "Headspace logo" }),
                    React.createElement("img", { src: yoga_club, width: "142", height: "34", alt: "Yogaclub logo" }))) : (React.createElement(React.Fragment, null,
                    React.createElement("img", { src: audible, width: "113", height: "32", alt: "Audible logo", style: { transform: 'translateY(-7px)' } }),
                    React.createElement("img", { src: tidal, width: "166", height: "23", alt: "Tidal logo" }))))),
            React.createElement(CoverRight, null,
                React.createElement(Bag, null,
                    React.createElement(LazyLoadImage, { src: bag, width: "490", height: "435", alt: "Spend image" })))),
        React.createElement(Block, null,
            React.createElement(CoverLeft, null,
                React.createElement(Heart, { src: heart },
                    React.createElement(LazyLoadImage, { src: heart, width: "518", height: "394", alt: "Donate image" }))),
            React.createElement(ContentRight, null,
                React.createElement(BlockTitle, null,
                    React.createElement(FormattedMessage, { ...messages.donateHeading })),
                React.createElement(Text, null,
                    React.createElement(FormattedMessage, { ...messages.donateText })),
                React.createElement(TextLink, null,
                    React.createElement(NavLink, { to: "/forgood" },
                        React.createElement(FormattedMessage, { ...messages.findOutMore }))),
                React.createElement(Logos, null,
                    React.createElement("img", { src: stc, width: "166", height: "34", alt: "Save the Children logo" }),
                    React.createElement("img", { src: cruk, width: "88", height: "34", alt: "Cancer Research logo" }),
                    React.createElement("img", { src: awf, width: "72", height: "34", alt: "African Wildlife Foundation logo" }))))));
};
const Wrapper = styled.div `
  margin: 0 16px;
  padding: 50px 0 0;

  @media ${desktop()} {
    width: 980px;
    margin: 0 auto;
    padding: 120px 0;
  }
`;
const Subtitle = styled.p `
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: -0.3px;
  line-height: 1.2;
  margin-bottom: 8px;

  @media ${desktop()} {
    font-size: 24px;
    margin-bottom: 16px;
    text-align: center;
  }
`;
const Title = styled.h2 `
  font-weight: 800;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 32px;
  letter-spacing: -0.3px;
  line-height: 1.2;
  margin-bottom: 18px;

  @media ${desktop()} {
    font-size: 48px;
    text-align: center;
    margin-bottom: 60px;
  }
`;
const Block = styled.div `
  position: relative;
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;

  @media ${desktop()} {
    display: block;
    padding-top: 70px;
    margin-bottom: 120px;
    padding-bottom: 90px;
  }
`;
const ContentLeft = styled.div `
  order: 2;

  @media ${desktop()} {
    width: 500px;
  }
`;
const ContentRight = styled.div `
  order: 2;

  @media ${desktop()} {
    width: 500px;
    margin-left: 480px;
  }
`;
const BlockTitle = styled.h4 `
  font-weight: 700;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 32px;
  line-height: 1.2;
  margin-bottom: 18px;

  @media ${desktop()} {
    font-size: 40px;
    line-height: 1.4;
    margin-bottom: 24px;
  }
`;
const Text = styled.p `
  font-family: 'Inter', sans-serif;
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  line-height: 1.4;
  margin-bottom: 18px;

  @media ${desktop()} {
    font-size: 24px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
`;
const Logos = styled.div `
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;

  @media ${desktop()} {
    justify-content: center;
  }

  img {
    width: auto;
    height: 26px;
    margin-right: 10px;

    @media ${desktop()} {
      height: 34px;
      margin: 0 35px;
    }

    &:last-child {
      margin-right: 0;

      @media ${desktop()} {
        margin-right: 35px;
      }
    }
  }
`;
const LogosTop = styled(Logos) `
  justify-content: center;

  img {
    max-width: 46%;
    height: auto !important;

    &:first-child {
      margin-right: 20px;

      @media ${desktop()} {
        margin-right: 35px;
      }
    }

    @media ${desktop()} {
      height: 34px;
      margin: 0 35px;
    }
  }
`;
const CoverRight = styled.div `
  order: 1;

  @media ${desktop()} {
    position: absolute;
    top: 0;
    right: 0;
  }
`;
const CoverLeft = styled.div `
  @media ${desktop()} {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
const Bag = styled.div `
  img {
    width: 80%;
    height: auto;
    margin: 0px auto 20px;
    display: block;

    @media ${desktop()} {
      width: 490px;
      position: relative;
      top: 17px;
      right: -38px;
      margin: 0;
    }
  }
`;
const Heart = styled.div `
  img {
    width: 80%;
    height: auto;
    margin: 0 auto 20px;
    display: block;

    @media ${desktop()} {
      width: 518px;
      position: relative;
      top: 40px;
      left: -82px;
      margin: 0;
    }
  }
`;
const Exchange = styled.div `
  img {
    width: 80%;
    height: auto;
    margin: 0 auto 20px;
    display: block;

    @media ${desktop()} {
      width: 470px;
      position: relative;
      top: -62px;
      right: -28px;
      margin: 0;
    }
  }
`;
const TextLink = styled.p `
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 24px;

  &::after {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    transform: rotate(45deg) translateY(5px) translateX(5px);
    background: url(${arrow}) 50% 50% no-repeat;
    background-size: contain;
    margin-left: 5px;
  }

  a {
    color: #fff;
    display: inline-block;
    position: relative;
    transition: all 0.3s ease-in-out;

    &:before {
      content: '';
      background: #fff;
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 0;
      height: 1px;
      transition: all 0.3s ease-in-out;
    }

    &:hover::before {
      width: 100%;
    }
  }
`;
export default SimpleSteps;
