import fetch from 'cross-fetch';
import debug from 'debug';
import get from 'lodash/get';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import ReactGA from 'react-ga4';
import { getIp } from './cloudflare';
const LOG = debug('analytics:ga');
const isClientSide = Boolean(process.browser);
const trackingEnabled = isClientSide
    ? typeof GA_TRACKING_CODE !== 'undefined' && GA_TRACKING_CODE
    : process.env.GA_TRACKING_CODE;
const trackServerEventData = async (eventData) => {
    try {
        const response = await fetch(`https://www.google-analytics.com/mp/collect?measurement_id=${process.env.GA_TRACKING_CODE}&api_secret=${process.env.GA_APP_SECRET}`, {
            method: 'POST',
            body: JSON.stringify(eventData),
        });
        // normally it's 204, no answer, response.json() will crash it
        if (!response.ok) {
            throw new Error('ga mp/collect fetch failed');
        }
    }
    catch {
        // TODO error handler
    }
};
const gaProvider = (ensureDeviceId) => {
    const init = () => {
        if (trackingEnabled && isClientSide) {
            ReactGA.initialize([
                {
                    trackingId: GA_TRACKING_CODE,
                    gaOptions: { clientId: ensureDeviceId() },
                },
            ]);
        }
    };
    const track = async (event_category, event_action, event_properties = {}, request) => {
        if (!trackingEnabled) {
            return;
        }
        if (isClientSide) {
            const eventData = pickBy({
                category: event_category,
                action: event_action,
                label: get(event_properties, 'label'),
                value: get(event_properties, 'value'),
            }, identity);
            ReactGA.event(eventData);
            LOG('Send event:', eventData);
            return;
        }
        const eventData = pickBy({
            ec: event_category,
            ea: event_action,
            el: get(event_properties, 'label'),
            ev: get(event_properties, 'value'),
            dp: get(event_properties, 'page'),
            dh: get(event_properties, 'origin'),
            ds: get(event_properties, 'platform'),
            ...(request
                ? {
                    dr: request.headers['referer'],
                    ua: request.headers['user-agent'],
                    uip: getIp(request),
                }
                : {}),
        }, identity);
        const data = { client_id: ensureDeviceId(request), events: [{ name: 'track', params: eventData }] };
        trackServerEventData(data);
        LOG('Send event:', data);
    };
    const pageview = async (event_type, event_properties = {}, request) => {
        if (!trackingEnabled) {
            return;
        }
        if (isClientSide) {
            const page_type = get(event_properties, 'page_type', null);
            const eventData = {
                hitType: 'pageview',
                title: get(event_properties, 'title'),
                page: get(event_properties, 'page'),
                ...(page_type && { page_type }),
            };
            ReactGA.send(eventData);
            LOG('Send pageview:', eventData);
            return;
        }
        const eventData = pickBy({
            dp: get(event_properties, 'page'),
            dt: get(event_properties, 'title'),
            dh: get(event_properties, 'origin'),
            ...(request
                ? {
                    dr: request.headers['referer'],
                    ua: request.headers['user-agent'],
                    uip: getIp(request),
                }
                : {}),
        }, identity);
        const data = { client_id: ensureDeviceId(request), events: [{ name: 'pageview', params: eventData }] };
        trackServerEventData(data);
        LOG('Send pageview:', data);
    };
    return { init, track, pageview };
};
export default gaProvider;
