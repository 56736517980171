import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import Slider from 'react-slick';
import styled from 'styled-components';
import { media } from 'components/responsive';
import chevron_next from '../../../../assets/S4G/chevron_next.png';
import chevron_prev from '../../../../assets/S4G/chevron_prev.png';
import quote from '../../../../assets/S4G/quote_left.svg';
const messages = defineMessages({
    text_1: "Donating to worthy causes through Sweatcoin makes me feel connected to Earth and to the people I’m helping, even if I may never meet them in my lifetime. For years, I've felt sad that I couldn’t contribute financially to the basic needs of my fellow humans. Sweatcoin has given me the opportunity to help others and all I have to do is walk.",
    text_2: "I'm not rich by any means but I'm blessed enough to have food, clothes, and a roof over my head. By donating through Sweatcoin, I'm able to help other people and that brings me joy.",
    text_3: "The feeling that you get, it's pretty amazing. I love knowing that I'm actually helping the planet, the people, and the animals. Sweatcoin is an awesome app, and I just want to say thank you for letting me help!",
    caption: 'Sweatcoin user',
});
const Testimonials = () => {
    return (React.createElement(StyledSlider, { adaptiveHeight: true },
        React.createElement(Container, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { ...messages.text_1 })),
            React.createElement(FormattedMessage, { ...messages.caption }, (caption) => (React.createElement(Name, null,
                "Nerci Jannete Rodriguez-Lopez",
                React.createElement("br", null),
                React.createElement("span", null, caption))))),
        React.createElement(Container, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { ...messages.text_2 })),
            React.createElement(FormattedMessage, { ...messages.caption }, (caption) => (React.createElement(Name, null,
                "Garrett Dunlap",
                React.createElement("br", null),
                React.createElement("span", null, caption))))),
        React.createElement(Container, null,
            React.createElement(Text, null,
                React.createElement(FormattedMessage, { ...messages.text_3 })),
            React.createElement(FormattedMessage, { ...messages.caption }, (caption) => (React.createElement(Name, null,
                "Vic Alvarez",
                React.createElement("br", null),
                React.createElement("span", null, caption)))))));
};
const Container = styled.div `
  margin: 40px auto 80px;

  ${media.desktop `
    margin: 100px auto 140px;
  `};

  &:before {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    background: url(${quote}) 50% 50% no-repeat;
    margin: 0 auto;
  }
`;
const StyledSlider = styled(Slider) `
  position: relative;

  .slick-arrow {
    display: none !important;

    ${media.desktop `
      display: block!important;
      z-index: 10;
      width: 52px;
      height: 52px;
      box-shadow: 0 15px 30px 0 rgba(253, 139, 123, 0.4);
      border-radius: 90px;
      font-size: 0;
      border: none;
      position: absolute;
      top: 260px;
      margin-top: -26px;
      cursor: pointer;
      transition: all 0.2s linear;
  
      &:hover {
        transform: translateY(1px);
        box-shadow: 0 5px 10px 0 rgba(253, 139, 123, 0.8);
      }
  
      &:active {
        transform: translateY(2px);
        box-shadow: 0 2px 4px 0 rgba(253, 139, 123, 0.8);
      }
  
      &.slick-prev {
        left: 0;
        background: #fd8b7b url(${chevron_prev}) 50% 50% no-repeat;
        background-size: 19px;
      }
  
      &.slick-next {
        right: 0;
        background: #fd8b7b url(${chevron_next}) 50% 50% no-repeat;
        background-size: 19px;
      }
    `};
  }
`;
const Text = styled.p `
  text-align: center;
  font-size: 24px;
  line-height: 140%;
  padding: 30px 25px 15px;

  ${media.desktop `
    font-size: 40px;
    line-height: 110%;
    padding: 40px 125px;
  `};
`;
const Name = styled.div `
  text-align: center;
  font-size: 18px;
  line-height: 140%;
  font-weight: bold;

  span {
    color: rgba(3, 23, 83, 0.5);
    font-size: 18px;
    font-weight: normal;
  }

  ${media.desktop `
    font-size: 24px;
  `};
`;
export default Testimonials;
