import React, { useEffect, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as ApiActions from 'actions/api';
import { media } from 'components/responsive';
import linkArrow from '../../../../assets/S4G/link_arrow.png';
const messages = defineMessages({
    link: 'Learn more',
});
const LiveCampaigns = () => {
    const dispatch = useDispatch();
    const [campaignState, setCampaignState] = useState();
    useEffect(() => {
        async function fetch() {
            const result = await dispatch(ApiActions.prefetchCrowdfundingCampaigns());
            setCampaignState(result);
        }
        fetch();
    }, []);
    return (React.createElement(Content, null, campaignState &&
        campaignState.payload.data.map((campaign, index) => {
            const link = '/offers/' + campaign.id;
            return !campaign.is_premium ? (React.createElement(Campaign, { key: index, href: link },
                React.createElement(Cover, { cover: campaign.featured_image }),
                React.createElement(Title, null, campaign.title),
                React.createElement(More, null,
                    React.createElement(FormattedMessage, { ...messages.link }),
                    React.createElement("img", { src: linkArrow })))) : null;
        })));
};
const Content = styled.div `
  margin: 25px auto 80px;
  ${media.desktop `
    margin: 55px auto 120px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    align-items: stretch;
  `}
`;
const Campaign = styled.a `
  flex: none;
  margin: 10px auto 0;
  width: 262px;
  box-shadow: 0px 30px 60px 0px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transition: all 0.2s ease;

  ${media.desktop `
    margin: 10px 12px 40px;
  `}

  &:hover {
    transform: translateY(3px);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
  }
`;
const Cover = styled.div `
  width: 244px;
  height: 244px;
  border-radius: 5px;
  margin: 10px auto 0;
  background-image: ${({ cover }) => `url(${cover})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;
const Title = styled.h4 `
  color: #031753;
  font-size: 18px;
  font-weight: bold;
  margin: 30px 30px 15px;
  flex: 1;
`;
const More = styled.p `
  line-height: 140%;
  font-size: 16px;
  color: #fd8b7b;
  font-weight: bold;
  margin: 0 30px 30px;

  img {
    display: inline-block;
    width: 16px;
    margin-left: 6px;
  }
`;
export default LiveCampaigns;
