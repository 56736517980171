import React from 'react';
import styled from 'styled-components';
import { media } from 'components/responsive';
import s4gPartner1 from '../../../../assets/S4G/awlf.png';
import s4gPartner2 from '../../../../assets/S4G/cancer_research.png';
import s4gPartner3 from '../../../../assets/S4G/conservation.png';
import s4gPartner4 from '../../../../assets/S4G/lonelywhale.png';
import s4gPartner5 from '../../../../assets/S4G/naacp.png';
const Partners = ({ title }) => {
    return (React.createElement(Container, null,
        title,
        React.createElement(Logos, null,
            React.createElement("img", { src: s4gPartner1, width: "100", alt: "Sweatcoin charity" }),
            React.createElement("img", { src: s4gPartner4, width: "55", alt: "Sweatcoin charity" }),
            React.createElement("img", { src: s4gPartner3, width: "100", alt: "Sweatcoin charity" }),
            React.createElement("img", { src: s4gPartner2, width: "130", alt: "Sweatcoin charity" }),
            React.createElement("img", { src: s4gPartner5, width: "140", alt: "Sweatcoin charity" }))));
};
const Container = styled.div `
  background-color: rgba(236, 238, 243, 0.4);
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 10px;
  padding-top: 35px;

  ${media.desktop `
    padding-top: 80px;
    margin-top: 120px;
    border-radius: 10px;
  `};
`;
const Logos = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 40px;
  padding-bottom: 10px;

  ${media.desktop `
    margin-top: 65px;
    padding-bottom: 95px;
  `};

  img {
    height: auto;
    display: block;
    margin: 0 18px 20px;

    ${media.desktop `
      margin: 0 40px;
    `};
  }
`;
export default Partners;
