import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled, { createGlobalStyle } from 'styled-components';
import FONTS from 'assets/fonts';
import Layout from 'components/Layout';
import { media } from 'components/responsive';
import buttonArrow from '../../../assets/S4G/button-arrow.svg';
import logo from '../../../assets/S4G/s4g_logo.png';
import Footer from '../components/ForGood/Footer';
import FundraisingCards from '../components/ForGood/FundraisingCards';
import LiveCampaigns from '../components/ForGood/LiveCampaigns';
import Partners from '../components/ForGood/Partners';
import PartnersCards from '../components/ForGood/PartnersCards';
import Popup from '../components/ForGood/Popup';
import SubtitleIcons from '../components/ForGood/SubtitleIcons';
import Team from '../components/ForGood/Team';
import Testimonials from '../components/ForGood/Testimonials';
const messages = defineMessages({
    title: 'Give people a cause <strong>worth sweating</strong> for',
    subtitle: "Welcome to Sweatcoin For Good (S4G). We're here to use our new technology to do good things for",
    mainCta: 'Work with us',
    partnersTitle: "We've worked with{nbsp}100+{nbsp}charities",
    fundraisingTitle: 'Fundraising in Sweatcoins',
    fundraisingSubtitle: 'Every week, we feature charitable campaigns for our users to support through their sweatcoins.',
    liveCampaignsTitle: 'Live Campaigns',
    teamTitle: 'Meet the S4G team',
    teamSubtitle: "We're so proud to be building out this amazing part of Sweatcoin if you'd like to know more get in touch:",
});
const meta = {
    title: 'Sweatcoin for Good - Every step counts',
    description: 'Sweatcoin for Good enables people to donate to charities by walking or running. Our app Sweatcoin converts steps into a digital currency for users to spend.',
};
const ForGood = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);
    return (React.createElement(Layout, { newHeader: true, logoAsset: logo },
        React.createElement(Helmet, { title: meta.title, meta: [
                { name: 'event-id', content: 's4g-page' },
                { property: 'og:title', content: meta.title },
                { name: 'twitter:title', content: meta.title },
                { name: 'description', content: meta.description },
                { property: 'og:description', content: meta.description },
                { name: 'twitter:description', content: meta.description },
                {
                    name: 'apple-itunes-app',
                    content: 'app-id=971023427',
                },
            ] }),
        React.createElement(GlobalStyle, { isPopupVisible: isPopupVisible }),
        isPopupVisible ? React.createElement(Popup, { onClickHandler: setPopupVisible }) : null,
        React.createElement(Container, null,
            React.createElement(Title, null,
                React.createElement(FormattedMessage, { ...messages.title })),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.subtitle }),
                React.createElement(SubtitleIcons, null)),
            React.createElement(FormLink, { onClick: () => setPopupVisible(true) },
                React.createElement(FormattedMessage, { ...messages.mainCta }),
                React.createElement("br", null),
                React.createElement("img", { src: buttonArrow, alt: "Arrow icon" })),
            React.createElement(Partners, { title: React.createElement(FormattedMessage, { ...messages.partnersTitle }, (title) => React.createElement(H2Title, null, title)) }),
            React.createElement(H2Title, null,
                React.createElement(FormattedMessage, { ...messages.fundraisingTitle })),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.fundraisingSubtitle })),
            React.createElement(FundraisingCards, null),
            React.createElement(PartnersCards, { onClickHandler: setPopupVisible }),
            React.createElement(Testimonials, null),
            React.createElement(H2Title, null,
                React.createElement(FormattedMessage, { ...messages.liveCampaignsTitle })),
            React.createElement(LiveCampaigns, null),
            React.createElement(H2Title, null,
                React.createElement(FormattedMessage, { ...messages.teamTitle })),
            React.createElement(Subtitle, null,
                React.createElement(FormattedMessage, { ...messages.teamSubtitle })),
            React.createElement(Team, null),
            React.createElement(Footer, { onClickHandler: setPopupVisible }))));
};
const GlobalStyle = createGlobalStyle `
  body {
    overflow: ${(props) => (props.isPopupVisible ? 'hidden' : 'auto')};
  }
`;
const Container = styled.div `
  width: auto;

  ${media.desktop `
    width: 1120px;
    margin-left: auto;
    margin-right: auto;
  `};
`;
const Title = styled.div `
  font-size: 32px;
  margin-top: 120px;
  text-align: center;
  line-height: 1;
  letter-spacing: 0.025rem;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  padding: 0 25px;

  strong {
    color: #fd8b7b;
  }

  ${media.desktop `
    margin-top: 200px;
    font-size: 64px;
    padding: 0 190px;
  `};
`;
const Subtitle = styled.div `
  margin-top: 15px;
  color: rgba(3, 23, 83, 0.5);
  font-size: 18px;
  text-align: center;
  line-height: 140%;
  font-family: ${FONTS.SimplonNorm.name}, ${FONTS.fallback};
  padding: 0 25px;

  ${media.desktop `
    margin-top: 30px;
    font-size: 24px;
    padding: 0 270px;
  `}
`;
const H2Title = styled.h2 `
  text-align: center;
  line-height: 140%;
  color: #031753;
  font-size: 28px;
  font-weight: 400;

  ${media.desktop `
    line-height: 100%;
    font-size: 42px;
  `}
`;
const FormLink = styled.button `
  box-sizing: border-box;
  width: 250px;
  background-color: #fd8b7b;
  overflow: visible;
  border-radius: 8px;
  border: none;
  text-transform: uppercase;
  line-height: 140%;
  text-align: center;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  display: block;
  margin: 50px auto 0;
  padding: 12px 0 10px;
  transition: all 0.2s linear;

  ${media.desktop `
    margin: 65px auto 0;
  `}

  &:hover {
    background-color: #e26e5e;
  }
`;
export default ForGood;
