import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { media } from 'components/responsive';
import janaSrc from '../../../../assets/S4G/jana-s4g.jpg';
import laraSrc from '../../../../assets/team/lara.jpg';
const messages = defineMessages({
    caption_1: 'Head of Sweatcoin{nbsp}for{nbsp}Good',
    caption_2: 'CSR Partnership Manager',
});
const Team = () => {
    return (React.createElement(Container, null,
        React.createElement(Item, null,
            React.createElement(Photo, null,
                React.createElement("img", { src: janaSrc, alt: "Jana Dowling" })),
            React.createElement(Title, null, "Jana Dowling"),
            React.createElement(Caption, null,
                React.createElement(FormattedMessage, { ...messages.caption_1 }))),
        React.createElement(Item, null,
            React.createElement(Photo, null,
                React.createElement("img", { src: laraSrc, alt: "Jessica Penny" })),
            React.createElement(Title, null, "Lara Raven"),
            React.createElement(Caption, null,
                React.createElement(FormattedMessage, { ...messages.caption_2 })))));
};
const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  ${media.desktop `
    margin-top: 0px;
    flex-direction: row;
    justify-content: center;
    align-items: stretch
  `};
`;
const Item = styled.div `
  width: 270px;
  text-align: center;
  box-shadow: 0 30px 60px 0 rgba(30, 68, 108, 0.08);
  background-color: #ffffff;
  overflow: visible;
  border-radius: 8px;
  padding: 32px 20px;

  ${media.desktop `
    margin: 55px 12px 0 ;
  `};
`;
const Title = styled.h4 `
  font-size: 18px;
  line-height: 140%;
  margin-bottom: 4px;
`;
const Caption = styled.p `
  font-size: 16px;
  line-height: 140%;
  color: rgba(3, 23, 83, 0.5);
`;
const Photo = styled.div `
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  height: 120px;
  margin: 0 auto 30px;

  img {
    width: 100%;
    height: auto;
  }
`;
export default Team;
