import React from 'react';
import styled from 'styled-components';
import close from '../../../../assets/S4G/close.svg';
const Popup = ({ onClickHandler }) => {
    return (React.createElement(Container, { onClick: () => onClickHandler(false) },
        React.createElement("iframe", { src: "https://docs.google.com/forms/d/e/1FAIpQLSd-UZFzMwWDwBP1_YmuC-8zXB5q2VAjCc-meGlYheAPwn7bDQ/viewform?embedded=true", frameBorder: "0", marginHeight: "0", marginWidth: "0" }, "Loading\u2026"),
        React.createElement(Close, { onClick: () => onClickHandler(false) },
            React.createElement("img", { src: close, alt: "Close icon" }))));
};
const Container = styled.div `
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  cursor: pointer;

  iframe {
    width: 900px;
    height: calc(100% - 80px);
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
  }
`;
const Close = styled.button `
  background-color: transparent;
  width: 60px;
  height: 30px;
  border: none;
  position: absolute;
  top: 40px;
  right: 40px;
  padding: 0;
  outline: none;
  cursor: pointer;

  img {
    width: 30px;
    height: 30px;
  }
`;
export default Popup;
